import { Component, ViewChild, OnInit, EventEmitter, Output, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-top-pickup-address',
  templateUrl: './top-pickup-address.component.html',
  styleUrls: ['./top-pickup-address.component.css']
})
export class TopPickupAddressComponent implements OnInit {

  @Input() adressType: string = "";
  @Input() appearance: string = "standard";
  @Input() setAddress: string = "";

  @Output() setPickupAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string = "";
  queryWait: boolean = true;

  pickupPlace: any;
  b_changeAddress: Boolean = false;

  constructor() {
  }

  ngOnInit() {
    this.autocompleteInput = this.setAddress;
  }

  ngAfterViewInit() {
      this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
      const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
          {
              types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
          });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const place = autocomplete.getPlace();
          this.invokeEvent(place);
      });
  }

  invokeEvent(place: Object) {
      // this.setAddress.emit(place);
      this.pickupPlace = place;
  }

  cancelChangeAddress() {
    this.b_changeAddress = false;
  }

  changeAddress() {
    this.b_changeAddress = !this.b_changeAddress;

    this.setPickupAddress.emit(this.pickupPlace);
  }

}
