<mat-toolbar>
    
    <a routerLink="/home" >
        <img width="100px" src="../../../assets/logo_735x735.png" class="logo" title="Real Estate" />
    </a>

    <span style="flex: 1 1 auto;"></span>
    <div class="top-call-info">
        <div class="top-line">
            <span>Call Now <a href="tel:825-712-8760">825-712-8760</a></span>
            <div>
              <button mat-icon-button matTooltip="My Account" [matMenuTriggerFor]="accountMenu">
                  <mat-icon>account_circle</mat-icon>
              </button>
              <mat-menu #accountMenu [overlapTrigger]="false" yPosition="below">
                  <button mat-menu-item routerLink="#">
                      <mat-icon>person</mat-icon><span>My Account</span>
                  </button>
                  <button mat-menu-item>
                      <mat-icon>help</mat-icon><span>Help</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="guard.authenticate()">
                      <mat-icon>login</mat-icon>Sign-in
                  </button>
              </mat-menu>
            </div>
        </div>
        <div class="second-line"><span>Our Rates HARD-TO-BEAT</span></div>
    </div>
    
    <!-- <div>
        <button mat-icon-button matTooltip="My Account" [matMenuTriggerFor]="accountMenu">
            <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #accountMenu [overlapTrigger]="false" yPosition="below">
            <button mat-menu-item routerLink="#">
                <mat-icon>person</mat-icon><span>My Account</span>
            </button>
            <button mat-menu-item>
                <mat-icon>help</mat-icon><span>Help</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="guard.authenticate()">
                <mat-icon>login</mat-icon>Sign-in
            </button>
        </mat-menu>
    </div> -->

</mat-toolbar>

