import { Component } from '@angular/core';
import {io} from 'socket.io-client/build/index';
import { AUTH_URL } from './_services/endPoint';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'customer';
  

  // users = [{ id: 1, nickname: "Loading..." }];

  constructor() {}

  ngOnInit() {
    // this.socket.on("connect", () => {
    //   console.log("--- socket id = ", this.socket.id);
    //   this.socket.emit('join', 'towing user 1');
    // });

    // this.socket.on("users", users => {
    //   this.users = users;

    //   // console.log("user list = ", this.users);
    // });

    // this.socket.on('connect_error', (err) => {
    //   console.log('error123',err)
    //   this.socket.connect();
    // });
  }
}

