import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ENDPOINT_URL } from './endPoint';

const API_URL = ENDPOINT_URL + 'api/vehicle/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  getChatByRoom(room): Observable<any> {
    // return new Promise((resolve, reject) => {
    //   this.http.get('/chat/' + room)
    //     .map(res => res.json())
    //     .subscribe(res => {
    //       resolve(res);
    //     }, (err) => {
    //       reject(err);
    //     });
    // });
    return this.http.post(API_URL + 'getChatByRoom', {
      "room": room
    }, httpOptions);
  }

  saveChat(data) {
    // return new Promise((resolve, reject) => {
    //     this.http.post('/chat', data)
    //       .map(res => res.json())
    //       .subscribe(res => {
    //         resolve(res);
    //       }, (err) => {
    //         reject(err);
    //       });
    // });
    return this.http.post(API_URL + 'saveChat', {
      "data": data
    }, httpOptions);
  }
}
