import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* FormsModule */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from '../angular-material.module';

import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { AutocompleteComponent  } from './google-places/google-places.component';
import { LoginComponent } from './login/login.component';
import { TopPickupAddressComponent } from './top-pickup-address/top-pickup-address.component';
import { ChatComponent } from './chat/chat.component';

@NgModule({
  declarations: [
    ScrollToTopComponent,
    AutocompleteComponent,
    LoginComponent,
    TopPickupAddressComponent,
    ChatComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    AutocompleteComponent,
    ScrollToTopComponent,
    TopPickupAddressComponent,
    ChatComponent
  ],

})
export class ComponentsModule { }