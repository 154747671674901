<div class="chat-window-container">
  <div class="chat-window-content">
    <div class="chat-window-header" (click)="chatContactListShowHide()">
      <div class="title">Chat</div>
      <div class="noti">
        <mat-icon>minimize</mat-icon>
      </div>
    </div>
    <div class="chat-window-contact-list" [ngClass]="{'show-contact-list': isChatWindowExpand}">
      <div class="contact-list">
        <div class="contact-item">
          <div class="user-avatar profilePicCircle">
            <span>A</span>
          </div>
          <div class="user-content">
            <div class="user-name">A Driver</div>
            <div class="last-msg">msgaasdlkfjaslkdfjlksadjflksadjfklsadjfklsajdfklsajdfklsjdfklj</div>
          </div>
        </div>
        <div class="contact-item">
          <div class="user-avatar profilePicCircle">
            <span>B</span>
          </div>
          <div class="user-content">
            <div class="user-name">B Driver</div>
            <div class="last-msg">msgaasdlkfjaslkdfjlksadjflksadjfklsadjfklsajdfklsajdfklsjdfklj</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>