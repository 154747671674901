<div class="top-div">
<div class="top-pickup-address">
  <div class="cust-home-title">
    <span>Book Order Online-Enter pick-up, Service Address</span>
  </div>
  <div class="top-address">

    <div class="autocompletecomponent">
      <mat-form-field appearance="{{appearance}}">
        <mat-label>Book Order Online-Enter pick-up, Service Address</mat-label>
        <input matInput #addresstext [(ngModel)]="autocompleteInput" placeholder="Book Order Online-Enter pick-up, Service Address"/>
      </mat-form-field>
    </div>
    <div class="change-address">
      <button *ngIf="!setAddress?.length" class="change-address-btn" (click)="changeAddress()">Add Address</button>
      <button *ngIf="setAddress?.length > 0" class="change-address-btn" (click)="changeAddress()">Change Address</button>
    </div>

  </div>
</div>
</div>
