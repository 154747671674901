<div class="login-content">

  <div [@vanish]="page">
    <h1 class="mat-title">{{ currentPage.title }}</h1>
    <p class="mat-small">{{ currentPage.message }}</p>
  </div>

  <form [formGroup]="form" fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="10px" (ngSubmit)="activate(page)">

    <!-- ERROR MESSAGE -->
    <mat-error *ngIf="error" @inflate>{{ error }}</mat-error>

    <!-- NAME -->
    <mat-form-field appearance="legacy" *ngIf="form.contains('name')" @inflate>
      <mat-label>Full name</mat-label>
      <input matInput formControlName="name">
      <mat-error *ngIf="form.controls.name.errors?.required">
        Please specify your name here
      </mat-error>
    </mat-form-field>

    <!-- EMAIL -->
    <mat-form-field appearance="legacy" *ngIf="form.contains('email')" @inflate>
      <mat-label>Email</mat-label>
      <input matInput formControlName="email">
      <mat-error *ngIf="form.controls.email.errors?.required">
        Please specify an email address
      </mat-error>
      <mat-error *ngIf="form.controls.email.errors?.email">
        Ooops! it looks like this is not a valid email
      </mat-error>
    </mat-form-field>

    <!-- PASSWORD -->
    <mat-form-field appearance="legacy" *ngIf="form.contains('password')" @inflate>
      <mat-label>Password</mat-label>
      <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password">
      <mat-icon matSuffix (click)="hidePassword = !hidePassword">
        {{ hidePassword ? 'visibility_off' : 'visibility'}}
      </mat-icon>
      <mat-error *ngIf="form.controls.password.errors?.required">
        A password is required
      </mat-error>
      <mat-hint class="mat-link" align="end" (click)="switchPage('forgotPassword')" *ngIf="page == 'signIn'" @inflate>Forgot password?</mat-hint>
    </mat-form-field>

    <!-- NEW EMAIL -->
    <mat-form-field appearance="legacy" *ngIf="form.contains('newEmail')" @inflate>
      <mat-label>New email</mat-label>
      <input matInput formControlName="newEmail">
      <mat-error *ngIf="form.controls.newEmail.errors?.required">
        A new email is required
      </mat-error>
      <mat-error *ngIf="form.controls.newEmail.errors?.email">
        This email looks wrong
      </mat-error>
    </mat-form-field>

    <!-- NEW PASSWORD -->
    <mat-form-field appearance="legacy" *ngIf="form.contains('newPassword')" @inflate>
      <mat-label>New  password</mat-label>
      <input matInput formControlName="newPassword">
      <mat-error *ngIf="form.controls.newPassword.errors?.required">
        A new password is required
      </mat-error>
    </mat-form-field>

    <!-- ACTION BUTTON -->
    <button mat-stroked-button color="primary" type="submit" [disabled]="!form.valid" class="btn">
      {{ currentPage.caption }}
    </button>

    <mat-progress-bar *ngIf="progress" mode="indeterminate" @inflate></mat-progress-bar>

  </form>

  <p class="mat-small" *ngIf="page == 'signIn'">
    Are you a new user? <span class="mat-link" (click)="switchPage('register')">Register</span>
  </p>

  <p class="mat-small" *ngIf="page === 'register' || page === 'forgotPassword'">
    Already have login and password? <span class="mat-link" (click)="switchPage('signIn')">Sign-in</span>
  </p>

  <!-- SIGN-IN PROVIDERS -->
  <!-- <div fxLayout="column" fxLayoutAlign="center center" *ngIf="page == 'signIn'" @inflate>

    <p class="mat-small">or sign-in with:</p>

    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px">

      <button mat-icon-button *ngFor="let p of providers" (click)="signInWith(p.name)">
        <mat-icon class="providers"
                  [fontSet]="p.icon.split(':')[0]"
                  [fontIcon]="p.icon.split(':')[1]" 
                  [ngStyle]="{ color: p.color }">
        </mat-icon>
      </button>
    </div>
  </div> -->

</div>